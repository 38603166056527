<div
    class="type-icon"
    [ngStyle]="{
        color:
            type === toastrType.SUCCESS
                ? 'rgba(70, 176, 70, 0.75)'
                : type === toastrType.WARNING
                  ? 'rgba(255, 191, 94, 0.75)'
                  : type === toastrType.INFO
                    ? 'rgba(76, 142, 255, 0.75)'
                    : 'rgba(203, 53, 34, 0.75)'
    }">
    <mat-icon *ngIf="type === toastrType.INFO" svgIcon="circle-info"></mat-icon>
    <mat-icon *ngIf="type === toastrType.SUCCESS" svgIcon="success"></mat-icon>
    <mat-icon *ngIf="type === toastrType.WARNING" svgIcon="warning"></mat-icon>
    <mat-icon *ngIf="type === toastrType.ERROR" svgIcon="error"></mat-icon>
</div>
<span class="message">
    {{ message }}
</span>
<div class="close-icon" (click)="close()">
    <mat-icon svgIcon="cancel"></mat-icon>
</div>
