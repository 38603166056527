import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { ToastComponent } from '../../ui/toast/toast.component';

export enum ToastrType {
    SUCCESS = 'Success',
    INFO = 'Info',
    WARNING = 'Warning',
    ERROR = 'Error',
}

@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    constructor(private snackbar: MatSnackBar) {}

    success(message: string) {
        if (message) {
            this.snackbar.openFromComponent(ToastComponent, { data: { message, type: ToastrType.SUCCESS } });
        }
    }

    error(message: string) {
        if (message) {
            this.snackbar.openFromComponent(ToastComponent, { data: { message, type: ToastrType.ERROR } });
        }
    }

    info(message: string): void {
        if (message) {
            this.snackbar.openFromComponent(ToastComponent, { data: { message, type: ToastrType.INFO } });
        }
    }

    warning(message: string): void {
        if (message) {
            this.snackbar.openFromComponent(ToastComponent, { data: { message, type: ToastrType.WARNING } });
        }
    }
}
