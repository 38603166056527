import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ToastrType } from '../../utils/services/toastr.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'webcoffee-toast',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ToastComponent {
    message = this.data.message;
    type = this.data.type;

    toastrType = ToastrType;

    constructor(@Inject(MAT_SNACK_BAR_DATA) private data: { message: string; type: ToastrType }, private snackbarRef: MatSnackBarRef<ToastComponent>) {}

    close() {
        this.snackbarRef.dismiss();
    }
}
